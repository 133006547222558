import Invite from "./components/who-we-are.components";

function App() {
	return (
		<div className="App">
			<Invite />
		</div>
	);
}

export default App;
